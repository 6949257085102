@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Raleway:wght@300&display=swap");

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: "Inter", sans-serif;
	font-optical-sizing: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: white;
	color: #101828;
}
