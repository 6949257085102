.container {
	border: 1px solid #f3f3f3;
	border-radius: 6px;
	display: flex;
	justify-content: space-between;
	margin: 4px;
	min-width: 70vw;
}

@media (max-width: 768px) {
	.container {
		flex-direction: column;
		width: 100vw;
		border: none;
		margin: 0;
		border-left: none;
	}
}
