/* Default text-input */
.default-text-input input {
	margin-left: 0px;
	border-radius: 0px;
	font-weight: 500;
	font-size: 12px;
	width: 100%;
}

.default-text-input {
	display: flex;
	background-color: #e8e8e89f;
	border-radius: 8px;
	padding: 4px 10px;
	height: 36px;
	align-items: center;
	color: #828488;
}

.default-text-input input {
	border: none;
	background-color: rgba(255, 255, 255, 0);
	margin-left: 8px;
}

.default-text-input input:focus {
	outline: none;
}
/* End Default text-input */

/* Start Payments Table */
table {
	border-collapse: collapse;
	width: 100%;
	color: #828488;
	font-size: 12px;
}

th {
	font-weight: 500;
	color: #828488;
	border-bottom: 1px solid #dddddd73;
	text-align: left;
	padding: 8px;
	padding-bottom: 12px;
}

td {
	border-bottom: 1px solid #dddddd73;
	text-align: left;
	padding: 24px 8px;
}

td:nth-child(1) {
	font-weight: 600;
}
/* End Payments Table */
